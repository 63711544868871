<template>
	<div>
		<QOverlay
			variant="dark"
			:show="isPopupOpen"
			class="layout-wrapper"
		>
			<template #overlay>
				<span />
			</template>
			<q-navbar
				class="navbar-expand-md"
				:nav-items="modules"
				hide-nav-children
			>
				<template #brand-image>
					<img
						class="mb-2"
						src="/assets/QubicaAMF_logo.png"
						alt=""
						width="50"
					>
				</template>

				<template #end>
					<div
						v-if="!isOnline"
						class="d-flex align-items-center text-danger user-select-none ms-md-2 mb-md-0 py-md-0 mb-1 py-2"
					>
						<i class="bi bi-globe2" />
						<span class="ms-1">
							{{ translateKey("offline") }}
						</span>
					</div>
					<span class="text-white-50 ms-md-3 mb-md-0 py-md-0 mb-1 py-2 text-nowrap">
						v{{ appVersion }}
					</span>
					<QButton
						v-if="areSettingsEnabled"
						variant="primary"
						class="ms-md-1 px-3 py-2 mb-1 mb-md-0 h3 rounded-lg"
						@click="showSettings = !showSettings"
					>
						<i class="bi bi-gear" />
					</QButton>
				</template>
			</q-navbar>
			<QSettings v-model="showSettings" />

			<div class="container py-3 h-100">
				<router-view />
			</div>
		</QOverlay>
	</div>
</template>
<script lang="ts" src="./app.ts"></script>
<style lang="scss">
.settings-dev {
	&__wrapper {
		display: grid;
		grid-template-columns: repeat(3, 1fr);
		align-content: center;
		gap: var(--spacer-4);
		margin: var(--spacer-0) var(--spacer-4) var(--spacer-0);
	}
}
</style>
